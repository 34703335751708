// Get the top position of an element in the document
function getTop(element, start) {
  return element.nodeName === 'HTML' ? -start : (element.getBoundingClientRect().top + start)
}

// ease in out function thanks to:
// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
const easeInOutCubic = t =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1

// calculate the scroll position we should be in
// given the start and end point of the scroll
// the time elapsed from the beginning of the scroll
// and the total duration of the scroll (default 500ms)
const position = (start, end, elapsed, duration) =>
  elapsed > duration
    ? end
    : (start + ((end - start) * easeInOutCubic(elapsed / duration)))

// we use requestAnimationFrame to be called by the browser before every repaint
// if the first argument is an element then scroll to the top of this element
// if the first argument is numeric then scroll to this location
// if the callback exist, it is called when the scrolling is finished
// if context is set then scroll that element, else scroll window
function smoothScroll(element, duration = 1000) {
  const clock = Date.now()
  const start = window.scrollTop || window.pageYOffset
  const end = typeof element === 'number'
    ? Number(element)
    : getTop(element, start)

  const requestAnimationFrame = window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      (function_ => window.setTimeout(function_, 15))

  const step = () => {
    const elapsed = Date.now() - clock
    window.scroll(0, position(start, end, elapsed, duration))

    if (elapsed < duration) requestAnimationFrame(step)
  }

  step()
}

function linkHandler(hash) {
  const node = document.getElementById(hash.slice(1))
  if (node) smoothScroll(node, 500)
}

export function scrollTo(node) {
  smoothScroll(node, 500)
}

export function scrollToHash(hash) {
  return (document.querySelectorAll === undefined ||
    window.pageYOffset === undefined ||
    history.pushState === undefined)
    ? selector => ({ selector })
    : linkHandler(hash)
}

// No smoothscroll on IE9 and below

export function scrollBehavior({ hash }) {
  if (!hash) return { x: 0, y: 0 }

  return scrollToHash(hash)
}
