<template functional>
  <section class="FormSect">
    <div v-if="props.title" class="title">
      <h3>{{ props.title }}</h3>
      <div v-if="props.sub" class="sub">
        {{ props.sub }}
      </div>
    </div>
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    sub: String,
    title: String,
  },
}
</script>

<style lang="scss">
section.FormSect {
  border-bottom: 1px solid $border-color;
  padding: 1rem;

  > .title {
    margin-bottom: 1rem;

    h3 {
      margin-bottom: 0;
    }

    .sub {
      font-size: $h5;
      line-height: 1rem;
    }
  }

  p {
    margin: 1rem 0;
  }
}
</style>
