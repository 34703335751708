<template>
  <div id="app">
    <AlertBar />
    <Modal v-if="modal.show" :modal="modal" />
    <!-- <Popup v-if="doShowPopup" /> -->
    <!-- <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        frameborder="0"
        height="50%"
        :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
        title="portfolioItem.location"
        width="50%" /> -->
    <CookieBar />
    <RouterView />
  </div>
</template>

<script>
import './validationRules'
import { actions, getters } from './views/utils'
import AlertBar from './views/components/AlertBar'
import CookieBar from './views/components/CookieBar'
// import Popup from './views/components/Popup'
import { defaultLocale } from './config'

export default {
  name: 'App',
  components: { AlertBar, CookieBar },
  data() {
    return {
      timerInterval: null,
      // doShowPopup: false,
    }
  },
  computed: getters(
    'doShowPopup',
    'implyInitialLocale',
    'isDesktop',
    'isPathDynamic',
    'modal',
    'referrers',
    'appStartedAt',
  ),
  methods: {
    ...actions(
      'AUTO_SET_LOCALE',
      'CHECK_SCREEN_SIZE',
      'DETECT_AB_EXPERIMENTS',
      'FETCH_SNIPPET',
      'INIT_LOCAL_STORAGE',
      'LOAD_LOCALE',
      'SET_COOKIE',
      'SET_LOCALE',
      'SET_POPUP_STATUS',
    ),
    tidioChatRegisterListener() {
      if (window.tidioChatApi) {
        window.tidioChatApi.on('messageFromOperator', this.onTidioOperatorMessage)
      } else {
        document.addEventListener('tidioChat-messageFromOperator', this.onTidioOperatorMessage)
      }
    },
    onTidioOperatorMessage({ data: { message = '' } = {} } = {}) {
      if (!(message.startsWith('Ačiū!') || message.startsWith('Puiku!'))) {
        return
      }

      this.$gtag.event('Siųsti', {
        event_category: 'Forma',
        event_label: 'PRIVATIEMS',
      })

      this.$analytics.fbq.event('Lead')
    },
  },
  watch: {
    '$route.params.locale': {
      handler(locale) {
        const localeCode = locale || defaultLocale
        this.SET_LOCALE(localeCode)
        document.documentElement.setAttribute('lang', localeCode)
      },
    },
  },
  async created() {
    this.INIT_LOCAL_STORAGE(this)

    await this.$nextTick()

    this.LOAD(Promise.all([
      this.FETCH_SNIPPET(),
    ]))

    this.CHECK_SCREEN_SIZE()

    this.$offEvents = await this.ON({
      resize: this.CHECK_SCREEN_SIZE,
    })

    const routeLocale = this.implyInitialLocale()
    const locale = await this.AUTO_SET_LOCALE({
      isHomepage: this.$route.name === 'Index',
      routeLocale,
    })

    if (locale !== routeLocale && !(locale === defaultLocale && !routeLocale)) {
      // NOTE: hackish way, gives time for $router to initialize
      setTimeout(() => {
        if (this.isPathDynamic(this)) return

        const routeName = this.$route.name || 'Index'
        this.$router.replace({ name: routeName, params: { locale } })
      }, 100)
    }

    // dispatch pre-render event only when locale is loaded
    this.POST_LOAD(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    })

    if (this.referrers) {
      this.SET_COOKIE({ key: 'referrers', value: this.referrers.r })
    }
    // await this.LOAD(this.DETECT_AB_EXPERIMENTS())

    // this.tidioChatRegisterListener()

    // Popups
    const POPUP_DELAY_MOBILE = 30 * 1000
    if (!this.isDesktop) {
      setTimeout(() => {
        this.SET_POPUP_STATUS(true)
      }, POPUP_DELAY_MOBILE)
    }
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
    clearInterval(this.timerInterval)
  },
}
</script>

<style lang="scss">
@import './views/style/global';
</style>
