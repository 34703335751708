<template>
  <transition appear name="slide-fade">
    <div v-show="allowCookies === null" id="CookieBar">
      <Row>
        <Column :md="9">
          <div class="message">
            <i18n path="useCookies" tag="div">
              <template #policy>
                <Route target="_blank" :title="$t('policy')" to="PrivacyPolicy" @click.native="fireGtagEvent('policy')">
                  {{ $t('policy') }}
                </Route>
              </template>
            </i18n>
            <a
              :href="$t('link')"
              rel="noreferrer"
              target="_blank"
              :title="$t('what')"
              @click="fireGtagEvent('cookies')"
              @keypress.enter="fireGtagEvent('cookies')">
              {{ $t('what') }}
            </a>
          </div>
        </Column>
        <Column :md="3">
          <Row class="actions">
            <Column :md="12" :xs="6">
              <Btn class="info sm" :icon="isDesktop ? 'check' : ''" @click="setCookie(true); fireGtagEvent('allow')">
                {{ $t('allow') }}
              </Btn>
            </Column>
            <Column :md="12" :xs="6">
              <Btn class="inv no-border sm" @click="setCookie(false); fireGtagEvent('disallow')">
                {{ $t('disallow') }}
              </Btn>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  </transition>
</template>

<script>
import { actions, getters } from 'views/utils'

export default {
  data() {
    return {
      allowCookies: true,
    }
  },
  computed: getters('isDesktop', 'localStorage'),
  methods: {
    fireGtagEvent(label) {
      this.$gtag.event('click', {
        event_category: 'cookieBar',
        event_label: label,
      })
    },
    setCookie(allowCookies) {
      this.allowCookies = allowCookies
      this.SET_LOCAL_STORAGE({ allowCookies })
    },
    ...actions('SET_LOCAL_STORAGE'),
  },
  created() {
    this.allowCookies = this.localStorage('allowCookies')
  },
}
</script>

<style lang="scss">
#CookieBar {
  background: $black;
  bottom: 0;
  box-shadow: $shadow-lg;
  color: $white;
  left: 0;
  padding: 0.5rem 0.5rem 1rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 7;
  @include md {
    padding: 1.5rem;
  }

  .message {
    padding: 1rem;
    @include md {
      padding: 1.5rem;
    }
  }

  .actions {
    align-content: center;
    height: 100%;
    text-align: center;

    .Btn {
      @include maxSm {
        padding: 0.5rem 1rem;
      }

      &.info {
        background: $info-dark;
        border-color: $info-dark;
      }
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "allow": "Sutinku",
    "disallow": "Ne, ačiū",
    "link": "https://gdpr.eu/cookies/",
    "policy": "Privatumo politikoje",
    "useCookies": "Siekdama užtikrinti tinkamą interneto svetainės veikimą bei naršymo kokybę, taip pat rinkodaros tikslais UAB „Saulės elektrinių inžinerija ir ranga“ naudoja slapukus. Jeigu sutinkate su slapukų įrašymu, spauskite “Sutinku”. Savo sutikimą bet kuriuo metu galite atšaukti pakeisdami naršyklės nustatymus ir ištrindami įrašytus slapukus. Daugiau informacijos {policy}.",
    "what": "Kas yra cookies?"
  }
}
</i18n>
