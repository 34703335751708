const loadQueue = []
const loadingPromise = null

export default {
  store: {
    state: {
      isLoaded: true,
      postLoad: [],
    },
    actions: {
      EXEC_POST_LOAD({ commit }) {
        commit('EXEC_POST_LOAD')
      },

      /*
        Sets state.isLoaded = true, after given loader Promise
        is resolved.
      */
      LOAD({ dispatch, commit, state }, loader) {
        if (loader) loadQueue.push(loader)

        const executeNext = async () => {
          if (!loadQueue.length) {
            commit('SET_LOADED', true)
            dispatch('EXEC_POST_LOAD')
            return null
          }

          const task = loadQueue[0]

          await task

          if (loadQueue.length) loadQueue.splice(0, 1)

          await executeNext()

          return loadingPromise
        }

        if (!loadQueue.length) return null

        if (state.isLoaded) {
          commit('SET_LOADED', false)
          return executeNext()
        }

        return loadingPromise
      },

      /*
        Triggers given function fn when page has been loaded.
      */
      POST_LOAD({ commit, state }, function_) {
        if (state.isLoaded) return function_()

        commit('ADD_TO_POSTLOAD', function_)
      },
    },

    mutations: {
      ADD_TO_POSTLOAD(state, function_) {
        state.postLoad.push({
          done: false,
          fn: function_,
        })
      },
      SET_LOADED(state, value) {
        state.isLoaded = value
      },
      EXEC_POST_LOAD(state) {
        state.postLoad.forEach((task) => {
          if (task.done) return
          task.done = true
          task.fn()
        })
      },
    },

    getters: {
      isLoaded: state => state.isLoaded,
    },
  },
}
