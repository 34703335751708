import { formExperimentId, isDeployedProduction } from '../../config'
import Vue from 'vue'

const getUrlParams = () => {
  try {
    return new URLSearchParams(window.location.search).get('r')
  } catch (error) {
    return null
  }
}

export default {
  store: {
    state: {
      appStartedAt: Date.now(),
      doShowPopup: false,
      hasPopupShowed: false,
      referrers: getUrlParams(),
      experiments: [],
    },
    getters: {
      isPathDynamic: () => ({ $route: route }) => {
        if (!route) return null

        const dynamicParameters = ['id', 'slug']

        return route && Object
          .keys(route.params)
          .some(parameter => dynamicParameters.includes(parameter))
      },
      referrers: (state) => {
        const referrers = state.referrers || Vue.$cookies.get('referrers')
        return (typeof referrers === 'string') ? { r: referrers } : referrers
      },

      appStartedAt: (state) => {
        return state.appStartedAt
      },

      doShowPopup: (state) => {
        return state.doShowPopup
      },

      hasPopupShowed: (state) => {
        return state.hasPopupShowed
      },

      getExperiment: ({ experiments }) => experimentId => experiments
        .find(experiment => experiment.experimentId === experimentId),
      // visited: state => state.visited,
      // url: state => state.url,
    },
    actions: {
      // SET_TIMESTAMP(_, { value }) {
      //   Vue.$cookies.set(key, value, expiration)
      // },
      SET_POPUP_STATUS(state, status) {
        if (state.hasPopupShowed) return
        state.commit('SET_POPUP_STATUS_VALUE', true)
      },
      SET_POPUP_SHOWED_STATUS({ commit }) {
        commit('SET_POPUP_SHOWED_STATUS_VALUE', true)
        commit('SET_POPUP_STATUS_VALUE', false)
      },
      SET_COOKIE(_, { key, value, expiration = '1y' }) {
        Vue.$cookies.set(key, value, expiration)
      },
      DETECT_AB_EXPERIMENTS({ commit }) {
        if (!isDeployedProduction) {
          commit('SET_AB_EXPERIMENTS', {
            combination: '1',
            experimentId: formExperimentId,
            containerId: null,
          })
          return
        }

        Vue.$gtag.event('optimize.callback', {
          callback: (combination, experimentId, containerId) => {
            commit('SET_AB_EXPERIMENTS', { combination, experimentId, containerId })
          },
        })
      },
    },
    mutations: {
      SET_AB_EXPERIMENTS(state, experiment) {
        state.experiments.push(experiment)
      },
      SET_POPUP_STATUS_VALUE(state, value) {
        state.doShowPopup = value
      },
      SET_POPUP_SHOWED_STATUS_VALUE(state, value) {
        state.hasPopupShowed = value
      },
      // SET_TIMESTAMP(state, value) {
      //   state.appStartedAt.push(value)
      // },
    },
  },
}
