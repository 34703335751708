export default {
  auto: '*',

  store: {
    // TODO: implement
    // hooks: {
    //   beforeQuery: (query, { getters }) => ({ ...query, locale: getters.locale }),
    //   beforeFetch: (query, { getters }) => ({ ...query, locale: getters.locale }),
    // },
  },
}
