import { castArray, isError, isNumeric } from '../views/utils'
import { isDeployedProduction, isMock } from '../config'
import { manipulations, stateList } from './utils'
import deepEquals from 'fast-deep-equal'
import index18n from '../i18n'
import modules from './modules'
import mutations from './mutations'
import { services } from './services'

const inject = (storeModule, manipulationKey) => ({ commit, getters }) => async (response) => {
  if (isError(response)) throw new Error(response)

  const results = getResults(response)

  if (storeModule.auto.has('mutations') && results) {
    manipulations[manipulationKey](storeModule)(results, { commit, getters })
  }

  return response.id
    ? results[0]
    : results
}

const actions = {
  async CLEAR_STATE({ commit }) {
    Object
      .keys(mutations)
      .filter(mutationName => mutationName.startsWith('CLEAR_'))
      .forEach(commit)
  },
}

const handleError = storeModule => store => (error) => {
  // do not recurisively throw errors for errors
  if ((!storeModule ||
    !storeModule.storeSuffix ||
    storeModule.storeSuffix !== 'ERROR') &&
    (error?.message.startsWith('Unexpected token'))) {
    error.message = index18n.t('errors.refresh') || 'Please refresh the page'
  }

  if (!isDeployedProduction && error.config) {
    error.message = `${isMock} || ${error.name} || ${error.message} || ${JSON.stringify(error.response)} || ${JSON.stringify(error.config)}`
  }

  if (!(error.isAxiosError && error.config?.method === 'get')) {
    store.dispatch('ERROR', error)
  }

  return Promise.reject(error)
}

function getResults(response) {
  if (!response) return null
  if (response.data) return castArray(response.data)
  return castArray(response)
}

const getPaginationString = ({ $limit, $skip }) => `${$limit || 20}|${$skip || 0}`

Object.values(modules).forEach((storeModule) => {
  if (!storeModule.auto.has('actions')) return

  const service = services[storeModule.serviceName]
  const addSuffix = actionName => `${actionName}_${storeModule.storeSuffix}`
  const addEntries = inject(storeModule, 'add')
  const addEntriesDirectly = store => response => addEntries(store, false)(response)
  const removeEntries = inject(storeModule, 'remove')
  const errorHandler = handleError(storeModule)
  const queryFetches = []

  // TODO: migrate to fully async/await
  /* eslint-disable promise/prefer-await-to-then */
  const actionsTemplate = {
    CREATE: (store, data) => service
      .create(data)
      .then(addEntries(store))
      .catch(errorHandler(store)),

    FIND_RAW: (store, query) => service
      .find(query)
      .then(addEntriesDirectly(store))
      .catch(errorHandler(store)),

    FIND: ({ dispatch }, query) =>
      dispatch(addSuffix('FIND_RAW'), query)
        .then(response => getResults(response)),

    GET: (store, id) => service
      .get(id)
      .then(addEntriesDirectly(store))
      .catch(errorHandler(store)),

    // similar to GET/FIND but with checking if it's already fetched
    FETCH: (context, query = {}) => {
      const { dispatch, state } = context
      const storeEntries = stateList(state[storeModule.storeKey])

      // GET of one entry by ID
      if (isNumeric(query)) {
        const id = parseInt(query, 10)

        return storeEntries[id]
          ? Promise.resolve(storeEntries[id])
          : dispatch(addSuffix('GET'), parseInt(query, 10))
      }

      // FIND by query
      const refetchTime = Date.now() - storeModule.cacheFor
      const { $page, ...serverQuery } = query
      const { $skip, ...queryWithoutPagination } = serverQuery
      const paginationString = getPaginationString(serverQuery)
      let recordedFetch = queryFetches
        .find(recordedFetch => deepEquals(recordedFetch.query, queryWithoutPagination))

      if (recordedFetch) {
        if (recordedFetch.fetchedAt[paginationString] > refetchTime) {
          return Promise.resolve([])
        }
        recordedFetch.fetchedAt[paginationString] = Date.now()
      } else {
        queryFetches.push({
          query: queryWithoutPagination,
          fetchedAt: {
            [paginationString]: Date.now(),
          },
        })

        recordedFetch = queryFetches[queryFetches.length - 1]
      }

      return dispatch(addSuffix('FIND_RAW'), serverQuery)
    },

    DELETE: (store, { id }) => service
      .remove(id)
      .then(removeEntries(store))
      .catch(errorHandler(store)),

    UPDATE: (store, { id, ...data }) => service
      .update(id, data)
      .then(addEntries(store))
      .catch(errorHandler(store)),

    PATCH: (store, { id, ...data }) => service
      .patch(id, data)
      .then(addEntries(store))
      .catch(errorHandler(store)),
  }
  /* eslint-enable promise/prefer-await-to-then */

  const storeModuleActions = Object
    .keys(actionsTemplate)
    .reduce((storeModuleActions, actionName) => {
      storeModuleActions[addSuffix(actionName)] = actionsTemplate[actionName]
      return storeModuleActions
    }, {})

  Object.assign(actions, storeModuleActions)
})

export default actions
