import { factory } from '../utils'

export default {
  auto: '*',
  store: {
    getters: {
      contactFactory: factory(() => ({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        address: null,
        roofType: null,
        roofDirection: null,
        monthFee: null,

        hasApva: null,
        hasAcceptedPolicy: false,
        hasSubscribed: false,
        message: null,

        // callDate: null,
        // callTime: null,
      })),
    },
  },
}
