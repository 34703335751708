/* global document */
import Modernizr from 'modernizr'
import Vue from 'vue'
import { basePath } from '../../config'
import { isObject } from '../../views/utils'

export default {
  store: {
    state: {
      screenSizes: ['xs'],
      screenWidth: 320,
    },
    getters: {
      basePath: () => basePath,
      isDesktop: (_, getters) => getters.screenSizes.includes('md'),
      screenSizes: state => state.screenSizes,
      screenWidth: state => state.screenWidth,

      splitIntoColumns: (_, { screenSizes }) => (entries, columnMap) => {
        const columnCountKey = Object.keys(columnMap).find(size => screenSizes.includes(size))
        const columnCount = columnMap[columnCountKey]
        return new Array(columnCount)
          .fill()
          .map((_, columnIndex) => entries
            .filter((_, index) => (index % columnCount) === columnIndex))
      },

      // TODO: rewrite in a similar fashion as splitIntoColumns
      splitIntoRows: (_, { screenSizes }) => (entries, columnMap) => {
        const columns = isObject(columnMap)
          ? columnMap[Object.keys(columnMap).find(size => screenSizes.includes(size))]
          : columnMap

        const keys = isObject(entries) ? Object.keys(entries) : null
        const iterable = (keys || entries)
        const rows = new Array(Math.ceil(iterable.length / columns))
          .fill()
          .map(() => [])

        iterable.forEach((entry, index) => {
          const row = rows[Math.floor(index / columns)]
          if (keys) {
            row[entry] = entries[entry]
          } else {
            row.push(entry)
          }
        })

        return rows
      },
    },

    actions: {
      async CHECK_SCREEN_SIZE({ commit }) {
        await Vue.nextTick()
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        commit('SET_SCREEN_WIDTH', width)
        commit('SET_SCREEN_SIZES', [
          ['xs', 0],
          ['sm', 576],
          ['md', 768],
          ['lg', 992],
          ['xl', 1200],
          ['xxl', 1601],
          ['xxxl', 1921],
        ].filter(([_, minWidth]) => width >= minWidth)
          .map(([size, _]) => size))
      },

      // async FETCH_STATE({ dispatch }, { exclude = [] } = {}) {
      //   const fetchOnLoad = []
      //   const fetchList = fetchOnLoad.reduce((fetchListAccumulator, next) => {
      //     fetchListAccumulator[next] = {
      //       $sort: { id: -1 },
      //     }
      //     return fetchListAccumulator
      //   }, {})

      //   return Promise.all(Object
      //     .keys(fetchList)
      //     .filter(serviceName => !(exclude.includes(serviceName)))
      //     .map(serviceName => dispatch(`FETCH_${serviceName}`, fetchList[serviceName])))
      // },

      ON({ dispatch }, events) {
        Object.keys(events).forEach((eventName) => {
          const passiveEvents = ['mousewheel', 'scroll', 'resize']

          const options =
            Modernizr.passiveeventlisteners && passiveEvents.includes(eventName)
              ? { passive: true }
              : false

          window.addEventListener(eventName, events[eventName], options)
        })

        return () => dispatch('OFF', events)
      },
      OFF(_, events) {
        Object.keys(events).forEach((eventName) => {
          window.removeEventListener(eventName, events[eventName])
        })
      },
    },

    mutations: {
      SET_SCREEN_SIZES(state, screenSizes) {
        state.screenSizes = screenSizes
      },
      SET_SCREEN_WIDTH(state, screenWidth) {
        state.screenWidth = screenWidth
      },
    },
  },
}
