export default {
  auto: '*',

  store: {
    getters: {
      getSnippetValue: (_, { query }) => key => (query('snippet', { key }, true) || {}).value,
      snippetsValues: (_, { getSnippetValue }) => Object
        .fromEntries(['constructedMw', 'developingMw', 'teamSize', 'teamSizeTotal']
          .map(key => [key, getSnippetValue(key)])),
    },
  },
}
