<template>
  <div class="Notice">
    <div class="text">
      <slot />
    </div>
    <Icon
      v-if="isClosable"
      class="close"
      name="cross"
      @click="$emit('close')" />
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      default: 'info',
      type: String,
    },
    isClosable: Boolean,
  },
}
</script>

<style lang="scss">
.Notice {
  background: $grey;
  color: $text-color;
  font-weight: $bold;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  text-align: center;

  > .close {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  // > .text {}

  .Btn {
    margin-right: 0.25rem;
  }

  &.danger {
    background: $danger-light;
    color: $danger;
  }

  &.info {
    background: $info-light;
    color: $info;
  }
  // &.neutral {
  //   background: $neutral-light;
  //   color: $neutral;
  // }
  &.warning {
    background: $warning-light;
    color: $warning-dark;
  }

  &.success {
    background: $success-light;
    color: $success-dark;
  }
}
</style>
