<template>
  <transition appear name="fade">
    <div class="Modal" :class="{ nested: isNested }">
      <div class="overlay" @click="close" @keydown.esc="close" />
      <div class="wrap">
        <div class="Modal-box">
          <ModalClose v-if="!isNested" @close="close" />
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actions } from '../../utils'

export default {
  props: {
    isNested: { type: Boolean, default: false },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    ...actions(),
  },
  async created() {
    if (this.isNested) return

    this.$offEvents = await this.ON({
      keydown: (event) => {
        if (event.keyCode === 27) this.close()
      },
    })
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
.Modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;

  > .overlay {
    background: rgba(darken($main, 55%), 0.7);
    height: 100%;
    pointer-events: all;
    position: fixed;
    width: 100%;
  }

  > .wrap {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    max-width: 840px;
    right: 0;
    top: 0;
    width: 100%;
  }

  &.nested {
    z-index: 6;

    > .overlay {
      background: transparent;
    }

    > .wrap {
      max-width: 550px;
    }
  }
}

.Modal-box {
  @include trans;
  background: $white;
  border-radius: $radius-md;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.3);
  max-height: 100vh;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  position: relative;
  width: 100%;
  z-index: 11;
  @include sm {
    max-width: 90%;
  }
  @include lg {
    max-width: 990px;
  }

  > .text {
    font-size: $h4;
    font-weight: $medium;
    padding: 2rem 2rem 1rem;
    position: relative;

    > .Icon {
      font-size: $h1;
      left: 2rem;
      position: absolute;
      top: 1rem;
    }

    > .type-confirm {
      margin-top: 1rem;
      text-align: left;

      .Field-cont {
        margin: 0;
      }
    }

    &.with-icon {
      padding-left: 6rem;
    }
  }
}
</style>
