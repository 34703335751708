<template>
  <div id="Footer">
    <Wrap>
      <div class="top">
        <div class="logo">
          <Pic alt="Nogrid - efektyvių saulės elektrinių ekspertai" src="../../assets/nogrid-logo.svg" />
          <div class="badges">
            <a class="diploma">
              <Pic :alt="$t('diplomaAlt')" class="diploma" src="../../assets/happy-customers-2000.svg" />
            </a>
            <a class="diploma">
              <Pic :alt="$t('diplomaAlt')" class="diploma" src="../../assets/best-price.svg" />
            </a>
            <br>
            <a class="diploma">
              <Pic :alt="$t('diplomaAlt')" class="diploma" src="../../assets/lsea.svg" />
            </a>
            <a class="diploma">
              <Pic :alt="$t('diplomaAlt')" class="diploma" src="../../assets/lstk.svg" />
            </a>
            <!-- <a class="diploma" :href="diplomaPath" target="_blank">
              <Pic :alt="$t('diplomaAlt')" :formats="['webp']" :src="diplomaLogo" />
            </a> -->
            <!-- <a class="diploma" href="https://baltojibanga.lt/nariai/" target="_blank">
              <Pic :alt="$t('diplomaAlt')" :src="clearwaveLogo" />
            </a> -->
          </div>
        </div>
        <div class="menu">
          <Row>
            <Column :md="2" :xl="4" :xs="0" />
            <Column v-for="menu in menus" :key="menu.label" :md="10 / 2" :xl=" 8 / 2">
              <div class="title">
                {{ menu.label }}
              </div>
              <ul v-for="({ isCareer, label, to }) in menu.links" :key="label">
                <li>
                  <Route :title="label" :to="to">
                    {{ label }}
                    <Tag v-if="isCareer" class="info">
                      {{ careerCount }}
                    </Tag>
                  </Route>
                </li>
              </ul>
            </Column>
          </Row>
        </div>
      </div>
      <div class="bottom">
        <Row>
          <Column :lg="3">
            <div class="social">
              <SocialIcon
                v-for="({ href, icon, label }) in socials"
                :key="label"
                :href="href"
                :icon="icon"
                :title="label" />
            </div>
          </Column>
          <Column :lg="6">
            <div class="logos">
              <a
                v-for="logo in logos"
                :key="logo.label"
                :class="logo.classes"
                :href="logo.href"
                rel="noopener"
                target="_blank"
                :title="logo.label">
                <Pic
                  :alt="logo.label"
                  :formats="['webp']"
                  :src="logo.src" />
              </a>
            </div>
          </Column>
          <Column :lg="3">
            <div class="copyright">
              <div>
                <div>
                  © {{ new Date().getFullYear() }} Nogrid
                </div>
                <div>
                  <Route target="_blank" :title="$t('policy')" to="PrivacyPolicy">
                    {{ $t('policy') }}
                  </Route>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { appendBasePath, getters, mergeLabels } from '../utils'
import SocialIcon from './SocialIcon'

export default {
  components: { SocialIcon },
  data() {
    return {
      diplomaPath: appendBasePath('assets/certs/DIPLOMAS_LT.pdf'),
      logos: mergeLabels([
        {
          href: 'https://saulesbendruomene.lt',
          src: '../../assets/saules-logo-small.png',
        },
        // {
        //   href: 'https://suninvestmentgroup.com',
        //   src: '../../assets/sig-logo-small.png',
        // },
        {
          href: 'https://eterniasolar.com',
          src: '../../assets/eternia-solar-logo-small.png',
        },
      ], this.$ta('logos')),
      socials: [
        {
          href: 'https://www.facebook.com/gonogrid/',
          icon: 'facebook',
          label: 'Facebook',
        },
        {
          href: 'https://www.linkedin.com/company/nogrid/',
          icon: 'linkedin',
          label: 'LinkedIn',
        },
        {
          href: 'https://www.youtube.com/channel/UC1iwV-8azNkPnDFByjNpYTQ',
          icon: 'youtube',
          label: 'Youtube',
        },
      ],
    }
  },
  computed: {
    diplomaLogo() {
      return this.screenSizes.includes('md')
        ? '../../assets/diploma-150px.png'
        : '../../assets/diploma-100px.png'
    },
    // clearwaveLogo() {
    //   return this.screenSizes.includes('md')
    //     ? '../../assets/clearwave-140.png'
    //     : '../../assets/clearwave-90.png'
    // },
    careerCount() {
      return this
        .query('career')
        .length
    },
    menus() {
      // const solutions = this.query('solution', {
      //   locale: this.locale,
      // })

      return [
        {
          label: this.$t('company.name'),
          links: mergeLabels([
            { to: 'Index' },
            { to: 'Faq' },
            { to: 'AboutUs' },
            { to: 'Blog' },
            { to: 'Contact' },
          ], this.$ta('company.menu')),
        },
        {
          label: this.$t('solutions.name'),
          links: mergeLabels([
            { to: 'SolarPlants' },
            { to: 'Portfolio' },
            { to: 'Financing' },
            // { to: 'Subsidy' },
          ], this.$ta('solutions.menu')),
        },
        // links: [
        //   ...solutions.map((solution) => ({
        //     label: solution.name,
        //     to: {
        //       name: solution.page,
        //     },
        //   })),
        // ],

      ]
    },
    ...getters('screenSizes'),
  },
}
</script>

<style lang="scss">
#Footer {
  background: $black;
  color: $white;
  font-weight: $semibold;
  padding-top: 4rem;
  @include md {
    padding-top: 8rem;
  }

  .Wrap {
    padding: 0 2.5rem;

    .top {
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;
      @include md {
        flex-direction: row;
      }

      > .logo {
        margin-bottom: 2rem;
        text-align: center;
        @include md {
          flex-grow: 1;
          text-align: left;
        }

        > .badges {
          margin-top: 1rem;
          @include md {
            margin-top: 0;
          }

          > .diploma {
            // display: inline-block;
            margin: 0 1rem;
            // margin-top: 1.5rem;
            text-align: center;
            @include md {
              margin-top: 4rem;
            }

            img {
              height: 10rem;
            }
          }
        }

        img {
          height: 3.5rem;
        }
      }

      > .menu {
        flex-grow: 4;

        .title {
          display: block;
          margin: 1.5rem 0;
          opacity: 0.4;
          @include md {
            margin-top: 0;
          }
        }

        .Tag {
          font-size: $h8;
          line-height: 0.5rem;
          min-width: 1.5rem;
          transform: translateY(0.25rem);
          vertical-align: top;
        }

        .Row {
          > div {
            text-align: center;
            // width: 100%;
            @include md {
              text-align: left;
            }
          }

          ul {
            list-style: none;

            > li {
              margin-bottom: 1.5rem;

              > a {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 4rem;

    > .Row {
      width: 100%;

      > div {
        padding: 0.5rem;
        text-align: center;

        &:first-child {
          @include lg {
            text-align: left;
          }
        }
      }
    }

    .social {
      flex-grow: 1;
    }

    .logos {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      text-align: center;
      @include lg {
        margin: 0;
        text-align: right;
      }

      > a {
        border: 0;
        display: inline-block;
        margin: 0 1rem;
        vertical-align: top;
        width: 25%;

        img {
          margin-left: 0;
          max-width: 100%;
          @include lg {
            width: auto;
          }
        }
      }
    }

    .copyright {
      color: $white;
      flex-grow: 2;
      font-weight: $regular;
      text-align: center;
      @include lg {
        text-align: right;
      }

      .Route {
        color: $white;
      }

      > div {
        margin-bottom: 0.5rem;
      }

      > a {
        color: $white;
      }
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "company": {
      "menu": [
        "Pagrindinis",
        "DUK",
        "Apie mus",
        "Tinklaraštis",
        "Kontaktai"
      ],
      "name": "Kompanija"
    },
    "solutions": {
      "menu": [
        "Saulės elektrinės",
        "Įgyvendinti projektai",
        "Finansavimas"
      ],
      "name": "Paslaugos"
    },
    "logos": [
      "Saulės Bendruomenė",
      "Sun Investment Group",
      "Nogrid",
      "ID Energias"
    ],
    "diplomaAlt": "Diplomas",
    "policy": "Privatumo politika"
  }
}
</i18n>
