<template functional>
  <Route
    :class="['MainLogo', data.class, data.staticClass]"
    :exact="true"
    title="Nogrid"
    to="Index">
    <Pic alt="Nogrid - efektyvių saulės elektrinių ekspertai" :src="props.src" />
    <a class="phone" :class="{ black: props.isScrolled }" href="tel:+370 691 02020">
      +370 691 02020
    </a>
  </Route>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '../../assets/nogrid-logo.svg',
    },
    isScrolled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.MainLogo {
  border: 0;
  display: inline-block;
  padding: 0.5rem 0;
  @include md {
    // padding: 1.25rem 0;
  }
  @include lg {
    padding-top: 0.5rem;
  }

  img {
    height: 32px;
    @include md {
      height: 36px;
    }
    @include md {
      height: 40px;
    }
    @include xxl {
      height: 60px;
    }
  }

  .phone {
    color: $white;
    display: block;
    text-align: center;
    @include xl {
      display: none;
    }

    &.black {
      color: $black;
    }
  }

  &.db {
    img {
      height: auto;
      width: 100%;
    }
  }

  &.router-link-exact-active {
    cursor: default;
  }
}
</style>
