<template>
  <Field
    class="FieldSubscribe"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      {{ $t('subscribe') }}
    </span>
  </Field>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>

<i18n>
{
  "lt": {
    "subscribe": "Noriu gauti Nogrid naujienlaiškį apie startuojančias APVA paramas ir kitas saulės elektrinių naujienas"
  }
}
</i18n>
