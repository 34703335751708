<template>
  <div class="NoticeRecaptcha">
    <i18n path="recaptcha" tag="div">
      <template #policy>
        <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank" :title="$t('policy')">
          {{ $t('policy') }}
        </a>
      </template>
      <template #terms>
        <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank" :title="$t('terms')">
          {{ $t('terms') }}
        </a>
      </template>
    </i18n>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.NoticeRecaptcha {
  font-size: $h8;
  margin: 1rem 0;
}
</style>

<i18n>
{
  "lt": {
    "policy": "Privatumo politika",
    "recaptcha": "Ši svetainė yra apsaugota reCAPTCHA, galioja Google {policy} ir {terms}.",
    "terms": "Sąlygos"
  }
}
</i18n>
