export const name = person => `${person.firstName || ''} ${person.lastName || ''}`.trim()

export const metricNumber = number => ['K', 'M', 'B']
  .reduce((string, letter) => parseInt(string, 10) > 1000
    ? Math.round(parseInt(string, 10) / 1000) + letter
    : string,
  number)

export const percent = number => number.toFixed(2).replace('.', ',') + '%'

export const kw = number =>
  ['kW', 'MW', 'GW', 'TW'].reduce((accumulator, suffix) => {
    if (typeof accumulator !== 'number') return accumulator
    if (accumulator < 1000) return `${accumulator}${suffix}`

    return accumulator / 1000
  }, number).replace('.', ',')

export function pad(string, length = 2, char = '0') {
  if (typeof string !== 'string') {
    string = string.toString()
  }

  const padLength = Math.max(length - string.length, 0)

  return padLength
    ? `${char.repeat(padLength)}${string}`
    : string
}

const pluralAmount = (amount, singular, plural) =>
  `${amount} ${amount === 1 ? singular : plural}`

export function duration(diff) {
  if (diff < 3600) {
    return pluralAmount(~~(diff / 60), 'minutės', 'minučių')
  } else if (diff < 86400) {
    return pluralAmount(~~(diff / 3600), 'valandos', 'valandų')
  } else if (diff < 2592000) {
    return pluralAmount(~~(diff / 86400), 'dienos', 'dienų')
  }

  return pluralAmount(~~(diff / 2592000), 'mėnesio', 'mėnesių')
}

export const money = (number, places = 2) => {
  const decimalSplit = number.toFixed(places).split('.')
  const isNegative = number < 0
  const out = decimalSplit[0]
    .replace('-', '')
    .split('')
    .reverse()
    .map((char, index, array) =>
      ((index + 1) % 3 === 0 && array.length !== index + 1) ? ',' + char : char)
    .reverse()
    .join('')

  const decimal = decimalSplit[1] ? `${out}.${decimalSplit[1]}` : out

  return isNegative ? '-' + decimal : decimal
}

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const currency = (number, symbol = '€', places) =>
  money(number, places).replace(/(-?)(.*)/, `$1$2 ${symbol}`)

export const slug = string => string.toLowerCase().replace(/ /g, '-')

export function lineBreak(value) {
  return value.replace(/\|/g, '\n')
}

export function commaDecimal(number, decimalPlaces = 2) {
  if (typeof number !== 'number') return ''

  return number.toFixed(decimalPlaces).replace('.', ',')
}
