import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { _ } from '@feathersjs/commons'
import { basePath } from '../../config'

export { default as queryObject } from './query'

// --------- Types ---------
export const castArray = array => Array.isArray(array) ? array : [array]

export const isObject = _.isObject
export const isObjectOrArray = _.isObjectOrArray
export const isNumeric = value => !isNaN(parseInt(value, 10))
export const isError = value => isObject(value) &&
  (value instanceof Error || (value.code && value.name && !value.id))

// --------- Object manipulation ---------
export const uniqueArray = array => [...new Set(castArray(array))]

export const mergeLabels = (objectsArray, labelsArray) => objectsArray
  .map((object, index) => ({
    ...object,
    ...(typeof labelsArray[index] === 'object'
      ? labelsArray[index]
      : { label: labelsArray[index] }),
  }))

// --------- Strings ---------
export const appendBasePath = path => `${basePath}${path}`

// http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
export const randomString = () => Math
  .random()
  .toString(36)
  .slice(2, 15)

// --------- Components ---------
export const registerComponents = components => Object
  .keys(components)
  .filter(componentName => !Vue.options.components[componentName])
  .forEach(componentName => Vue.component(
    componentName,
    components[componentName],
  ))

export const getters = (...array) => mapGetters([
  'authUser',
  'isAuth',
  'hasRole',
  'isLoaded',
  'query',
  'locale',
  ...array,
])

export const actions = (...array) => mapActions([
  // loaders
  'LOAD',
  'POST_LOAD',

  // error handling
  'ERROR',

  // success alert
  'SUCCESS',

  // window events
  'ON',

  ...array,
])
