// import Index from '../views/pages/Index'
import MainLayout from '../views/layouts/Main'
import { defaultLocale } from '../config'
import localeStore from '../store/modules/locale'

const nonDefaultLocales = localeStore.store.state.locales
  .map(locale => locale.value)
  .filter(localeCode => localeCode !== defaultLocale)
  .join('|')

const rootPath = nonDefaultLocales.length
  ? `/:locale(${nonDefaultLocales})?`
  : ''

export default [
  {
    path: rootPath,
    component: MainLayout,
    children: [
      {
        path: 'apie-mus',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/AboutUs'),
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/Blog'),
      },
      {
        path: 'blog/:slug',
        name: 'BlogItem',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/BlogItem'),
      },
      // {
      //   path: 'karjera',
      //   name: 'Career',
      //   component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Career'),
      // },
      {
        path: 'kontaktai',
        name: 'Contact',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Contact'),
      },
      // {
      //   path: 'naujienos',
      //   name: 'NewsPress',
      //   component: () => import(/* webpackChunkName: 'news' */ '../views/pages/NewsPress'),
      // },
      // {
      //   path: 'naujienos/:slug',
      //   name: 'Post',
      //   component: () => import(/* webpackChunkName: 'news' */ '../views/pages/Post'),
      // },
      {
        path: 'igyvendinti-projektai',
        name: 'Portfolio',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Portfolio'),
      },
      // {
      //   path: 'igyvendinti-projektai/:id',
      //   name: 'PortfolioItem',
      //   component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/PortfolioItem'),
      // },
      {
        path: 'privatumo-politika',
        alias: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: 'policy' */ '../views/pages/PrivacyPolicy'),
      },
      // {
      //   path: 'apzvalga',
      //   name: 'Overview',
      //   component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Overview'),
      // },
      // {
      //   path: 'es-parama',
      //   name: 'EUSupport',
      //   component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/EUSupport'),
      // },
      {
        path: 'duk',
        name: 'Faq',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Faq'),
      },
      {
        path: 'paskola-saules-elektrinems',
        name: 'Financing',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Financing'),
      },
      {
        path: 'finansavimas/:slug',
        name: 'FinancingBank',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Financing'),
      },
      {
        path: 'saules-elektrines',
        name: 'SolarPlants',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/SolarPlants'),
      },
      {
        path: 'saules-elektrines-verslui',
        name: 'SolarPlantsB2b',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/SolarPlantsB2b'),
      },
      {
        path: 'apva-parama-saules-elektrinems',
        name: 'Subsidy',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Subsidy'),
      },
      {
        path: 'landing-static',
        name: 'LandingPageStatic',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/LandingPageStatic'),
      },
      {
        path: 'pasiulymas-be-investiciju',
        name: 'LandingPageInvestment',
        component: () =>
          import(/* webpackChunkName: 'pages' */ '../views/pages/LandingPageInvestment'),
      },
      {
        path: 'apva-parama-elektrinei',
        name: 'LandingPageApva',
        component: () =>
          import(/* webpackChunkName: 'pages' */ '../views/pages/LandingPageApva'),
      },
      {
        path: 'geriausios-kainos-garantija',
        name: 'LandingPageSale',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/LandingPageSale'),
      },
      {
        path: '',
        name: 'Index',
        // component: Index,
        component: () =>
          import(/* webpackChunkName: 'index' */ '../views/pages/Index'),
      },
    ],
  },
  {
    path: '/atsiliepimas',
    name: 'ReviewForm',
    component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/ReviewForm'),
  },
  {
    path: '/seminaras',
    name: 'SeminarForm',
    component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/SeminarForm'),
  },
  {
    path: '/denied',
    name: 'Error401',
    component: () => import(/* webpackChunkName: 'pages' */ '../views/errors/401.vue'),
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: 'pages' */ '../views/errors/404.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: 'landing' */ '../views/layouts/Landing'),
    children: [
      {
        path: ':slug',
        name: 'Landing',
        component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/Landing'),
      },
      {
        path: '*',
        name: 'Error404',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/errors/404.vue'),
      },
    ],
  },
]
