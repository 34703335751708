<template>
  <div class="FormContactB">
    <FormValidation :stepIndex="activeStepIndex" @submit="nextStep">
      <div v-if="!hasSent">
        <Row v-if="activeStep">
          <Column
            v-for="field in activeStep.fields"
            :key="field.label"
            :class="{ multiple: isMultipleActiveFields }"
            :md="12 / activeStep.fields.length">
            <Field
              v-bind="field"
              :label="$t(field.label)"
              :placeholder="$t('placeholderText')"
              :placeholderSelect="$t('placeholderSelect')"
              :value.sync="contact[field.valueKey]" />
          </Column>
        </Row>
        <FieldConsent v-if="isConsentStep" :value.sync="contact.hasAcceptedPolicy" />
        <template v-if="isFinalStep">
          <NoticeRecaptcha />
          <FieldSubscribe :value.sync="contact.hasSubscribed" />
        </template>
      </div>
      <Notice v-else-if="message" :class="message.theme">
        {{ message.text }}
      </Notice>

      <Btn
        v-if="!isFirstStep && !hasSent"
        class="bright button-back"
        @click="prevStep">
        {{ $t('back') }}
      </Btn>
      <Btn
        v-if="!hasSent"
        class="bright"
        :isLoading="isSending"
        type="submit">
        {{ $t('next') }}
      </Btn>
    </FormValidation>
  </div>
</template>

<script>
import Contactable from '../../mixins/Contactable'
import FieldSubscribe from './../FieldSubscribe'
import { getters } from './../../utils'

export default {
  mixins: [Contactable],
  components: { FieldSubscribe },
  data() {
    return {
      stepEventsSent: [],
      steps: [
        {
          name: 'electricity-bill-v2',
          fields: [
            {
              label: 'monthFee',
              name: 'monthFee',
              type: 'text',
              valueKey: 'monthFee',
            },
          ],
        },
        {
          name: 'contact-details-v2',
          fields: [
            {
              autocomplete: 'email',
              label: 'email',
              type: 'email',
              required: true,
              valueKey: 'email',
            },
            {
              autocomplete: 'phone',
              label: 'phone',
              type: 'text',
              maxlength: 14,
              minlength: 8,
              rules: 'phone',
              required: true,
              valueKey: 'phone',
            },
          ],
        },
        {
          name: 'address-v2',
          fields: [
            {
              autocomplete: 'street-address',
              label: 'address',
              type: 'text',
              valueKey: 'address',
            },
          ],
        },
        {
          name: 'roof-v2',
          fields: [
            {
              label: 'roofType.question',
              name: 'roofType',
              type: 'select',
              options: this.$ta('roofType.options'),
              valueKey: 'roofType',
            },
            {
              label: 'roofDirection.question',
              name: 'roofDirection',
              type: 'select',
              options: this.$ta('roofDirection.options'),
              valueKey: 'roofDirection',
            },
          ],
        },
        {
          name: 'name-surname-v2',
          fields: [
            {
              autocomplete: 'given-name',
              label: 'name',
              type: 'text',
              valueKey: 'firstName',
            },
            {
              autocomplete: 'family-name',
              label: 'lastName',
              type: 'text',
              valueKey: 'lastName',
            },
          ],
        },
        {
          name: 'apva-v2',
          fields: [
            {
              label: 'apva.question',
              name: 'apva',
              type: 'select',
              options: () => this.apvaOptions,
              valueKey: 'hasApva',
            },
          ],
        },
        {
          name: 'form-submit-v2',
          fields: [
            {
              label: 'message',
              class: 'textarea',
              type: 'textarea',
              valueKey: 'message',
            },
          ],
        },
      ],
      activeStepIndex: 0,
    }
  },
  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex]
    },
    isMultipleActiveFields() {
      return this.activeStep.fields.length > 1
    },
    lastIndex() {
      return this.steps.length - 1
    },
    isConsentStep() {
      return this.activeStepIndex === 1
    },
    isFinalStep() {
      return this.activeStepIndex === this.lastIndex
    },
    isFirstStep() {
      return this.activeStepIndex === 0
    },
    isSuccessStep() {
      return this.activeStep.name === 'contact-details-v2'
    },
    ...getters('getExperiment'),
  },
  methods: {
    async nextStep() {
      if (!this.stepEventsSent.includes(this.activeStepIndex)) {
        this.$gtag.event('step', {
          event_category: 'contact-form',
          event_label: this.activeStep.name,
        })
        this.stepEventsSent.push(this.activeStepIndex)
      }

      if (this.activeStepIndex > 0) { // Second step
        this.send(this.isFinalStep, this.isSuccessStep)
      }

      this.activeStepIndex += 1
    },
    prevStep() {
      if (!this.isFirstStep) {
        this.activeStepIndex -= 1
      }
    },
  },
}
</script>

<style lang="scss">
.FormContactB {
  .Row {
    align-content: center;
    justify-content: center;

    .Column {
      &.multiple {
        padding-bottom: 3rem;
        @include md {
          padding-bottom: 0;
        }

        &:last-of-type {
          padding-bottom: 0;

        }

      }
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  span {
    color: $white;

  }

  .button-back {
    margin-right: 1rem;
  }

  .Field {
    input,
    select,
    textarea,
    input[type="number"] {
      border-bottom: 1px solid $white;
      border-radius: 0;
      color: $white;
    }

    select > option {
      color: $black-ter;
    }

    &.with-icon {
      border: none;
    }
  }

  label {
    font-size: 1.35rem;
    @include xl {
      font-size: 2rem;
    }
  }

  // fixes active checkbox label styling in last step
  .Field-cont.active {
    .Field.type-checkbox,
    .Field.type-radio {
      label {
        font-size: $h5;
      }
    }
  }

  .Field-cont {
    &.FieldConsent {
      margin-bottom: 2rem;
    }

    &.FieldSubscribe {
      margin-bottom: 2rem;

      label {
        line-height: 1.2;
      }
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "placeholderText": "Atsakymą įveskite čia",
    "placeholderSelect": "Pasirinkite",
    "email": "El. paštas",
    "message": "Kiti komentrai ir pageidavimai",
    "name": "Vardas",
    "lastName": "Pavardė",
    "phone": "Telefono nr.",
    "apva": {
      "question": "Ar gavote APVA paramą?",
      "options": [
        "Taip",
         "Ne"
      ]
    },
    "address": "Adresas",
    "roofType": {
      "question": "Stogo tipas",
      "options": [
        "Šlaitinis",
        "Plokščias"
      ]
    },
    "roofDirection": {
      "question": "Stogo kryptis",
      "options": [
        "Pietūs",
        "Rytai",
        "Vakarai",
        "Šiaurė",
        "Nežinau",
        "Kita"
      ]
    },
    "monthFee": "Kiek per mėnesį sumokate už elektrą?",
    "send": "Išsiųsti",
    "success": "Dėkojame! Netrukus su jumis susisieksime.",
    "date": "Pasirinkite jums patogų laiką ir datą skambučiui",
    "next": "Toliau",
    "back": "Atgal"
  }
}
</i18n>
