import 'lazysizes'
import * as components from './views/components/globals'
import * as filters from './views/utils/filters'
import { ValidationObserver, ValidationProvider, configure, setInteractionMode } from 'vee-validate'
import { facebookPixelId, googleAnalyticsId, isProduction } from './config'
import App from './App'
import Router from 'vue-router'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueGtag from 'vue-gtag'
// import VueGtm from 'vue-gtm'
import VueHead from 'vue-head'
import VueLocalStorage from 'vue-localstorage'
import { _ } from '@feathersjs/commons'
import { attachBugsnag } from './bugsnag'
import index18n from './i18n'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(VueHead)
Vue.use(VueCookies)

// VeeValidate
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
configure({
  defaultMessage: (_, values) => index18n.t(`rules.${values._rule_}`, values),
})
setInteractionMode('eager')

// global filters
_.each(filters, (filter, name) => Vue.filter(name, filter))

// global components
_.each(components, (component, tag) => Vue.component(tag, component))

Vue.use(Router)
Vue.use(VueLocalStorage)

// Vue.use(VueGtm, {
//   id: googleTagManagerId,
//   loadScript: isDeployedProduction,
//   vueRouter: router,
//   enabled: !!googleTagManagerId,
// })

Vue.use(VueGtag, {
  config: { id: googleAnalyticsId },
  disableScriptLoad: !isProduction,
  enabled: isProduction,
}, router)

Vue.use(VueFacebookPixel)
if (isProduction && facebookPixelId) {
  Vue.analytics.fbq.init(facebookPixelId)
  Vue.analytics.fbq.event('PageView')
}

new Vue({
  i18n: index18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$recaptchaToken = async function() {
  return 'none'
}

attachBugsnag()
