export const manipulations = (() => {
  return {
    add: ({ storeSuffix }) => (results, { commit }) =>
      commit(`ADD_${storeSuffix}`, results),

    remove: ({ storeSuffix }) => (results, { commit }) =>
      commit(`REMOVE_${storeSuffix}`, results),
  }
})()

export const factory = factoryFunction => (_, getters) => (overrides = {}) => ({
  ...factoryFunction(getters),
  ...overrides,
})

export const getterOptions = (getterKey, nullOption) => (_, getters) => [
  ...nullOption ? [nullOption] : [],
  ...getters[getterKey].map(({ label, value }) => [value, label]),
]

export const getterFinder = getterKey => (_, getters) => valueToFind =>
  getters[getterKey].find(({ value }) => valueToFind === value)

export const stateOptions = valueFunction => state => Object
  .values(stateList(state))
  .map(entry => [entry.id, valueFunction(entry)])

export const stateList = state => state.list || state
