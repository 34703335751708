<template>
  <button
    class="Btn"
    :class="classes"
    :type="type"
    @click="click"
    @keypress.enter="click">
    <Icon v-if="icon && !isLoading" :name="icon" />
    <Loader v-if="isLoading" />
    <slot v-else />
  </button>
</template>

<script>
import Link from 'views/mixins/Link'
import { actions } from 'views/utils'

export default {
  mixins: [Link],
  props: {
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  computed: {
    classes() {
      return [
        this.disabled && 'disabled',
        this.isLoading && 'loading',
        this.icon && 'with-icon',
      ]
    },
  },
  methods: {
    click() {
      if (this.isLoading || this.disabled) {
        event.preventDefault()
        return
      }

      // if (this.track) {
      //   this.GEVENT({ category: 'Button', label: this.track })
      // }

      this.go()
      this.$emit('update:isLoading', true)
      this.$emit('click')
    },
    ...actions(),
  },
}
</script>

<style lang="scss">
$_loader-color: rgba($main, 0.2);
$_loader-radius: 1.5rem;

button.Btn,
.Btn {
  @include trans;
  background: $main;
  border: 2px solid $main;
  border-radius: $radius-lg;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $h8;
  font-weight: $semibold;
  letter-spacing: 1px;
  margin: 0;
  outline: 0;
  padding: 1.25rem 2.5rem;
  position: relative;
  text-align: center;

  // link hover fix
  &[href]:hover,
  &[href]:focus {
    color: $white;
  }

  // themes
  &.inv {
    background: transparent;
    color: $main;
  }

  &.dark {
    background: $black-ter;
    border-color: $black-ter;
    color: $white;
  }

  &.bright {
    background: $white;
    border-color: $white;
    color: $main;
  }

  // sizes
  &.sm {
    padding: 0.5rem 1.5rem;
  }

  // states
  &.loading {
    cursor: default;
  }

  &.disabled {
    background-color: $neutral-light;
    border-color: $neutral-dark;
    color: $white;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: $neutral;
    }
  }

  // styles
  &.no-border {
    border: 0;
  }

  // interactions
  &:hover,
  &:focus {
    background-color: $main-lightish;
  }
}

// Button loader
.Btn {
  > .Loader {
    border-width: 0.2rem;

    &, &::after {
      height: $_loader-radius;
      width: $_loader-radius;
    }
  }

  &.inv,
  &.link {
    > .Loader {
      border-left-color: $main;
      color: $_loader-color;
    }
  }
}
</style>
