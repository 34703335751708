<template functional>
  <picture
    :class="['Pic', data.class, data.staticClass]"
    v-on="listeners">
    <source
      v-for="([format, formatSrc, formatType]) in $options.sources(props)"
      :key="format"
      :data-srcset="formatSrc"
      :srcset="data.attrs.loading === 'lazy' ? undefined : formatSrc"
      :type="formatType">
    <img
      v-bind="data.attrs"
      :alt="props.alt"
      :class="{ lazyload: data.attrs.loading === 'lazy' }"
      :data-src="srcAbs"
      :src="data.attrs.loading === 'lazy' ? undefined : $options.srcAbs(props.src)">
  </picture>
</template>

<script>
const formatTypes = {
  jpg: 'jpeg',
  svg: 'svg+xml',
}

function absoluteSourcePath(source) {
  if (source.startsWith('.')) {
    return require(`@/assets/${source.replace(/(\.\.?\/)+assets\//, '')}`)
  }

  return source
}

function getFormatAbsolutePath(relativePath, format) {
  return absoluteSourcePath(`${relativePath}.${format}`)
}

function formSource(relativePath, format) {
  return [
    format,
    getFormatAbsolutePath(relativePath, format),
    `image/${formatTypes[format] || format}`,
  ]
}

export default {
  props: {
    alt: {
      type: String,
      default: '',
    },
    formats: Array,
    src: String,
  },
  sources(props) {
    const pathWithoutFormat = [...props.src.split('.').slice(0, -1)].join('.')

    return [
      ...(props.formats || []),
      props.src.match(/\w*$/)[0],
    ].map(format => formSource(pathWithoutFormat, format))
  },
  srcAbs(source) {
    return absoluteSourcePath(source)
  },
}
</script>
