import { defaultLocale } from '../../config'
import index18n from '../../i18n'

const locales = [
  {
    value: 'lt',
    label: 'Lietuviškai',
  },
  // {
  //   value: 'en',
  //   label: 'English',
  // },
  // {
  //   value: 'pl',
  //   label: 'Polski',
  // },
]

export default {
  store: {
    state: {
      // browserTimezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
      browserLanguage: (navigator || {}).language || null,
      defaultLocale,
      locale: implyInitialLocale(locales) || defaultLocale,
      locales,
    },
    getters: {
      browserLanguage: state => state.browserLanguage.toLowerCase(),
      defaultLocale: state => state.defaultLocale,
      implyInitialLocale: state => () => implyInitialLocale(state.locales),
      locale: state => state.locale,
      locales: state => state.locales,
    },
    actions: {
      AUTO_SET_LOCALE({ dispatch, getters, state }, { isHomepage, routeLocale } = {}) {
        const localesCodes = state.locales.map(locale => locale.value)
        const nonDefaultLocales = localesCodes.filter(localeCode => localeCode !== defaultLocale)

        const locale = ((() => {
          // maintains routeLocale except in homepage
          if (routeLocale && (!isHomepage || routeLocale !== defaultLocale)) {
            return routeLocale
          }

          const storedLocale = getters.localStorage('locale')
          if (storedLocale && localesCodes.includes(storedLocale)) return storedLocale

          if (Array.isArray(navigator.languages)) {
            const nonDefaultLanguage = nonDefaultLocales
              .find(localeCode => navigator.languages.includes(localeCode))

            if (nonDefaultLanguage) return nonDefaultLanguage
          }

          if (!state.browserLanguage) return defaultLocale

          const browserLocale = state
            .locales
            .map(locale => locale.value)
            .find(localeValue => state.browserLanguage.includes(localeValue))

          if (browserLocale) return browserLocale

          return defaultLocale
        })()).toLowerCase()

        dispatch('SET_LOCALE', locale)
        return locale
      },
      SET_LOCALE({ commit, dispatch }, locale) {
        commit('SET_LOCALE', locale)
        dispatch('SET_LOCAL_STORAGE', { locale })
        // Vue.i18n.set(locale)
        index18n.locale = locale
      },
    },
    mutations: {
      SET_LOCALE(state, locale) {
        state.locale = locale
      },
    },
  },
}

function implyInitialLocale(locales) {
  const { pathname } = window.location

  return locales
    .map(locale => locale.value)
    .find(localeCode => pathname.startsWith(`/${localeCode}/`) || pathname === `/${localeCode}`)
}
