<template functional>
  <div :class="['Loader', props.theme, data.class, data.staticClass]" />
</template>

<script>
export default {
  props: {
    theme: String,
  },
}
</script>

<style lang="scss">
/* stylelint-disable-next-line unit-whitelist */
$_radius: 2em;
$_color: $white;
$_opacity: 0.2;
$_color-trans: rgba($_color, $_opacity);

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Loader {
  animation: load8 1.1s infinite linear;
  border-left-color: $_color;
  border-style: solid;
  border-width: ($_radius / 10);
  color: $_color-trans;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  text-indent: -9999rem;
  transform: translateZ(0);

  &, &::after {
    border-radius: 50%;
    height: $_radius;
    width: $_radius;
  }

  &.success {
    border-left-color: $success;
  }
}
</style>
