import client from './client'
import { isError } from '../views/utils'
import modules from './modules'

/*
* Global data collector.
*/
function parseResponse(response) {
  if (typeof response === 'undefined') throw new Error('Undefined response')

  if (Array.isArray(response)) return response

  if (isError(response)) return new Error(response)

  return response
}

/*
* Global error handler.
*/
function handleError(error) {
  if (error?.message) {
    if (error.name === 'NotAuthenticated') {
      error.message = 'Please re-login'
      error.button = {
        text: 'Login',
        route: 'Login',
      }

      throw new Error(error)
    }

    throw error
  }

  throw new Error(error)
}

export const services = modules.reduce((servicesMap, { serviceName }) => {
  if (!serviceName) return servicesMap

  const service = client.getService(serviceName)

  const methods = {
    create: data => service.create(data),
    get: id => service.get(id),
    find: query => service.find({ query }),
    delete: (id, params) => service.remove(id, params),
    update: (id, data, params) => service.update(id, data, params),
    patch: (id, data, params) => service.patch(id, data, params),
  }

  servicesMap[serviceName] = Object
    .keys(methods)
    .reduce((servicesMap, methodKey) => {
      servicesMap[methodKey] = async (idOrDataOrQuery, paramsOrData, params) => {
        try {
          const response = await methods[methodKey](idOrDataOrQuery, paramsOrData, params)
          return parseResponse(response)
        } catch (error) {
          return handleError(error)
        }
      }

      return servicesMap
    }, {})

  return servicesMap
}, {})

export { client }
