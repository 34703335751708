import { _ } from '@feathersjs/commons'
import { isMock } from '../../config'

const { merge } = _

const modules = require
  .context('.', true, /js$/)
  .keys()
  .filter(path => !path.includes('index'))
  .map(path => path.replace(/(\.\/)|(\.js)/g, ''))
  .reduce((modulesMap, key) => Object
    .assign(modulesMap, { [key]: require(`./${key}`).default }), {})

export default Object.keys(modules).map((moduleKey) => {
  const storeModule = modules[moduleKey]

  if (!storeModule.auto) storeModule.auto = []

  const auto = new Set(storeModule.auto === '*'
    ? ['actions', 'getters', 'mutations', 'sync']
    : storeModule.auto)

  return Object.assign(
    merge({
      // external service name without apiBase (someService)
      serviceName: auto.has('actions')
        ? `${moduleKey}${isMock ? '.json' : ''}`
        : null,

      // suffix for store actions (SOME_SERVICE)
      storeSuffix: moduleKey.split(/(?=[A-Z])/).join('_').toUpperCase(),
      isClearable: auto.has('mutations') && storeModule.isClearable !== false,
      isFrozen: true,

      cacheFor: 10000,

      // internal store key (someService)
      storeKey: moduleKey,
      store: {
        state: {
          list: {},
        },
      },
    }, storeModule),
    { auto })
})
