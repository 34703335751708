import { isObject } from '../../views/utils'
import { isProduction } from '../../config'

export default {
  store: {
    state: {
      $localStorage: null,
    },
    getters: {
      localStorage: state => (key) => {
        const storedValue = state.$localStorage.get(key)

        try {
          return JSON.parse(storedValue)
        } catch (_) {
          return storedValue
        }
      },
    },
    actions: {
      SET_LOCAL_STORAGE({ state }, entries) {
        try {
          Object
            .keys(entries)
            .forEach((key) => {
              const value = entries[key]
              const storedValue = isObject(value) ? JSON.stringify(value) : value
              state.$localStorage.set(key, storedValue)
            })
        } catch (error) {
          if (!isProduction) throw error
        }
      },
      INIT_LOCAL_STORAGE({ commit }, vueComponentInstance) {
        commit('INIT_LOCAL_STORAGE', vueComponentInstance)
      },
    },
    mutations: {
      INIT_LOCAL_STORAGE(state, { $localStorage }) {
        state.$localStorage = $localStorage
      },
    },
  },
}
