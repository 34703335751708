import {
  email,
  max,
  min,
  required,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('min', min)
extend('max', max)
extend('email', email)
extend('required', required)
extend('checkedBox', {
  validate: value => value === true,
})
extend('phone', {
  validate: value => /^\+?[\d\s]+$/.test(value),
})
