import { isProduction } from '../../config'

function wrap(alert) {
  return typeof alert === 'object'
    ? alert
    : { text: alert }
}

export default {
  store: {
    state: {
      list: [],
    },
    getters: {
      alerts: state => state.list,
    },
    actions: {
      ALERT({ commit, dispatch }, alert) {
        commit('ADD_ALERT', alert)

        if (isProduction || alert.theme !== 'danger') {
          setTimeout(() => {
            dispatch('CLOSE_ALERT', alert)
          }, 4000)
        }
      },
      SUCCESS({ dispatch }, text) {
        dispatch('ALERT', { theme: 'success', ...wrap(text) })
      },
      DANGER({ dispatch }, text) {
        dispatch('ALERT', { theme: 'danger', ...wrap(text) })
      },
      CLOSE_ALERT({ commit }, alert) {
        commit('REMOVE_ALERT', alert)
      },
    },
    mutations: {
      ADD_ALERT(state, alert) {
        state.list.push(alert)
      },
      REMOVE_ALERT(state, { text }) {
        state.list = state.list.filter(alert => alert.text !== text)
      },
    },
  },
}
