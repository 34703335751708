<template>
  <div>
    <RouterView />
    <TopBar v-if="!pagesWithoutTopBar.includes($route.name)" />
    <FooterContact v-if="!pagesWithoutFooterContact.includes($route.name)" />
    <Footer v-if="!pagesWithoutFooter.includes($route.name)" />
  </div>
</template>

<script>
import Footer from 'views/components/Footer'
import FooterContact from 'views/components/FooterContact'
import TopBar from 'views/components/TopBar'

export default {
  components: { Footer, FooterContact, TopBar },
  data() {
    return {
      pagesWithoutTopBar: [
        'LandingPageApva',
        'LandingPageInvestment',
        'LandingPageStatic',
        'LandingPageSale',
      ],
      pagesWithoutFooterContact: [
        'AboutUs',
        'Career',
        'LandingPageApva',
        'LandingPageInvestment',
        'LandingPageStatic',
        'SolarPlantsB2b',
        'LandingPageSale',
      ],
      pagesWithoutFooter: [
        'LandingPageApva',
        'LandingPageInvestment',
        'LandingPageStatic',
      ],
    }
  },
}
</script>

<style lang="scss">

</style>
