<template>
  <Row class="Splitter no-gutters">
    <Column class="left" :md="left">
      <slot name="left" />
    </Column>
    <Column class="right" :md="right">
      <div class="Splitter-container">
        <div class="Splitter-body">
          <slot name="right" />
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>
export default {
  props: {
    left: { type: Number, default: 6 },
    right: { type: Number, default: 6 },
  },
}
</script>

<style lang="scss">
.Splitter {
  flex-direction: column;
  @include md {
    flex-direction: row;
  }

  .right,
  .left {
    @include md {
      width: 100%;
    }
  }

  &-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &-body {
    max-width: 40rem;
    padding: 2rem 1rem;
    @include md {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.reverse {
    flex-direction: column-reverse;
    @include md {
      flex-direction: row-reverse;
    }
  }
}
</style>
