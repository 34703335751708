export default {
  store: {
    state: {
      paginations: [],
    },
    getters: {
      getPagination: ({ paginations }) => paginationName =>
        paginations.find(pagination => pagination.name === paginationName),
    },
    actions: {
      PUSH_PAGINATION({ commit }, pagination) {
        commit('PUSH_PAGINATION', pagination)
      },
      PATCH_PAGINATION({ commit }, { pagination, ...patch }) {
        commit('PATCH_PAGINATION', { pagination, ...patch })
      },
    },
    mutations: {
      PUSH_PAGINATION(state, pagination) {
        state.paginations.push(pagination)
      },
      PATCH_PAGINATION(_, { pagination, ...patch }) {
        Object
          .keys(patch)
          .forEach((key) => {
            pagination[key] = patch[key]
          })
      },
    },
  },
}
