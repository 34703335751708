import Vue from 'vue'
import { castArray } from '../views/utils'
import modules from './modules'
import { stateList } from './utils'

const toIdMap = entries => Object.fromEntries(entries.map(entry => [entry.id, entry]))

function buildStoreMutations({ isClearable, isFrozen, storeKey, storeSuffix, store }) {
  if (!storeSuffix) return {}

  const list = stateList(store.state)

  const storeMutations = {
    ADD: (state, entries) => {
      const entriesValuesArray = castArray(entries)
      if (!entriesValuesArray.length) return

      if (!isFrozen && entriesValuesArray.length === 1) {
        Vue.set(list, entriesValuesArray[0].id, {
          ...(list[entriesValuesArray[0].id] || {}),
          ...entriesValuesArray[0],
        })
        return
      }

      const appendValues = toIdMap(entriesValuesArray)
      const jointState = { ...list, ...appendValues }
      const updatedState = isFrozen ? Object.freeze(jointState) : jointState
      Vue.set(state[storeKey], 'list', updatedState)
    },
    REMOVE: (state, entries) => {
      const idsToStrip = castArray(entries).map(entry => entry.id)

      if (!isFrozen) {
        idsToStrip.forEach((id) => {
          Vue.delete(list, id)
        })
        return
      }

      const remainingValues = toIdMap(Object
        .values(list)
        .filter(id => !idsToStrip.includes(id)))

      Vue.set(state[storeKey], 'list', Object.freeze({
        ...remainingValues,
      }))
    },
  }

  if (isClearable) {
    storeMutations.CLEAR = (state) => {
      Vue.set(state[storeKey], 'list', {})
    }
  }

  return Object.fromEntries(Object
    .entries(storeMutations)
    .map(([key, function_]) => [`${key}_${storeSuffix}`, function_]))
}

const mutations = modules
  .filter(storeModule => storeModule.auto.has('mutations'))
  .reduce((storeMutations, storeModule) => Object
    .assign(storeMutations, buildStoreMutations(storeModule)), {})

export default mutations
