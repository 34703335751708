<script>
export default {
  data() {
    return {
      message: null,
    }
  },
  methods: {
    setErrorMessage(error) {
      if (error.errors?.length) {
        this.danger(error.errors[0])
      } else {
        this.danger(error.message)
      }
    },
    setMessage({ theme = 'neutral', text = '' } = {}) {
      this.message = { theme, text }
    },
    clearMessage() {
      this.message = null
    },
    success(text) {
      this.setMessage({ theme: 'success', text })
    },
    warning(text) {
      this.setMessage({ theme: 'warning', text })
    },
    danger(text) {
      this.setMessage({ theme: 'danger', text })
    },
  },
}
</script>
