<template functional>
  <div :class="['Tag', data.class, data.staticClass]">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.Tag {
  background: $white-ter;
  border-radius: $radius-md;
  display: inline-block;
  font-size: $h6;
  // font-weight: $semibold;
  padding: 0.5rem;
  text-align: center;

  // size
  &.md {
    border-radius: 2rem;
    font-size: $h7;
    padding: 0.75rem 1.75rem;
  }

  &.sm {
    font-size: $h8;
    padding: 0.5rem 1rem;
  }

  // themes
  &.info {
    background: $info;
  }
}

</style>
