<template>
  <section id="FooterContact" :class="{ [background]: background, vertical: isVertical }">
    <Wrap class="xl">
      <div v-if="isVertical">
        <h2 class="title">
          {{ contactTitle | lineBreak }}
        </h2>
        <FormContactSteps />
      </div>
      <Splitter v-else :left="4" :right="8">
        <div slot="left">
          <h2 class="title">
            {{ contactTitle | lineBreak }}
          </h2>
        </div>
        <div slot="right">
          <FormContactB />
          <!-- <FormContactA v-if="formVariant === '1'" :key="1" /> -->
          <!-- <FormContactB v-else-if="formVariant === '2'" :key="2" /> -->
          <!-- <FormContactSteps v-else :key="3" /> -->
          <!-- <FormContactC /> -->
        </div>
      </Splitter>
    </Wrap>
  </section>
</template>

<script>
import FormContactB from './FormTestAB/FormContactB'
import FormContactSteps from './FormContactSteps'
import Splitter from './Splitter'
import { getters } from '../utils'

export default {
  components: { FormContactB, FormContactSteps, Splitter },
  props: {
    background: String,
    title: String,
    isVertical: Boolean,
  },
  data() {
    return {
      variant: 0,
    }
  },
  computed: {
    // formVariant() {
    //   const experiment = this.getExperiment(formExperimentId)

    //   return experiment ? experiment.combination : 0
    // },
    contactTitle() {
      return this.title || this.$t('title')
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#FooterContact {
  background-color: $main;
  color: $white;
  padding: 2rem 0 3rem;
  @include md {
    background-image: url('../../assets/ellipses-full.png');
    background-position: top left;
    padding: 10rem 0;
    // padding: 5rem 0 10rem;
  }

  .Splitter {
    .Column.left {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      @include lg {
        text-align: initial;
      }

      .title {
        white-space: pre-line;
      }
    }

    .Column.right {
      .Splitter-body {
        max-width: unset;
        width: 100%;
      }
    }
  }

  h2 {
    font-size: 2rem;
    margin: 2rem 0;
    @include md {
      font-size: $h2;
      margin: 0;
      padding-bottom: 3rem;
    }
  }

  .FormContact {
    form {
      margin: 0;
    }

    .Field-cont {
      margin-bottom: 3rem;
      @include md {
        margin-bottom: 2rem;
      }

      .Field-errors {
        color: $danger;
      }

      .Field {
        input,
        textarea {
          color: $white;

          &:focus {
            border-color: $white;
          }
        }

        &.type-checkbox {
          label {
            color: $white;

            a {
              color: $white;
              text-decoration: underline;
            }
          }
        }
      }

      &.active {
        label {
          color: $white;
        }
      }

      &.textarea {
        margin-bottom: 0;
      }
    }

    .NoticeRecaptcha {
      a {
        color: $white;
        text-decoration: underline;
      }
    }

    button.Btn {
      background: $white;
      color: $main;

      > .Loader {
        color: $main;
      }

      &:hover,
      &:focus {
        background: $info-light;
      }
    }
  }

  &.vertical {
    background-image: none;
  }
}
</style>

<i18n>
{
  "lt": {
    "title": "Atsakykite į kelis|klausimus ir gaukite|Nogrid pasiūlymą!"
  }
}
</i18n>
