<script>
import { actions, getters } from 'views/utils'
// import DatePicker from './DatePicker'
import Message from 'views/mixins/Message'
import NoticeRecaptcha from '../components/NoticeRecaptcha'
// import TimePicker from './TimePicker'
import { isDeployedProduction } from '../../config'
import { softThrow } from '../../bugsnag'

export default {
  mixins: [Message],
  components: {
    NoticeRecaptcha,
    // DatePicker,
    // TimePicker,
  },
  data() {
    return {
      // date: null,
      // time: null,
      contact: this.$store.getters.contactFactory(),
      isSending: false,
      hasSent: false,
    }
  },
  computed: {
    apvaOptions() {
      const labels = this.$ta('apva.options')
      return [true, false].map((value, index) => [value, labels[index]])
    },
    ...getters('isDesktop', 'referrers'),
  },
  methods: {
    async send(isLastStep = true, isSuccessStep = true) {
      try {
        this.isSending = true

        // this.contact.callDate = (this.date ? this.date.toISOString() : null)
        // this.contact.callTime = (this.time ? this.time.toISOString() : null)

        if (isDeployedProduction) {
          const recaptcha = await this.$recaptchaToken('contact')
          // TODO: check if contact in Strapi with current email exists and patch it if true
          await this.CREATE_CONTACT({
            ...this.contact,
            metaReferrers: this.referrers,
            timezone: ((new Date()).getTimezoneOffset() / 60),
            recaptcha,
          })
        }

        if (isSuccessStep) {
          // this.$gtag.event('Siųsti', {
          //   event_category: 'Forma',
          //   event_label: 'PRIVATIEMS',
          // })
          this.$gtag.event('success', {
            phoneNumber: this.contact.phone,
            emailAddress: this.contact.email,
          })
          this.$analytics.fbq.event('Lead')
        }
      } catch (error) {
        error.body = this.contact
        softThrow(error)
      } finally {
        this.isSending = false
        if (isLastStep) {
          this.hasSent = true
          this.success(this.$t('success'))
        }
      }
    },
    ...actions('CREATE_CONTACT'),
  },
}
</script>

<style lang="scss">
.FormContact {
  margin: 3rem 0 0;

  .label-date {
    @include trans;
    color: inherit;
    display: block;
    font-size: $h7;
    // font-weight: $semibold;
    // left: 0.5rem;
    line-height: 1rem;
    margin: 0.75rem 0 0;
    padding-left: 0.75rem;
    pointer-events: none;
    // text-transform: uppercase;
    top: 0.25rem;
    @include md {
      line-height: inherit;
    }
  }

  .checkboxes {
    margin-top: 0.5rem;
    text-align: left;
    @include md {
      margin-top: 0;
    }
  }

  form {
    @include lg {
      margin-top: 3rem;
    }

    input,
    select,
    textarea {
      text-align: left;
      text-align-last: left;
    }

    .checkboxes {
      .Field-errors {
        margin-bottom: 1rem;
      }

      .submit-column {
        padding-top: 1rem;
        text-align: center;
        @include md {
          text-align: right;
        }
      }
    }

    .Btn {
      margin: auto;
      max-width: 20rem;
      min-width: 8rem;
      padding: 1.25rem 0;
      width: 100%;
      @include sm {
        width: auto;
      }
    }
  }

  .Notice {
    margin-top: 2rem;
  }
}
</style>

<i18n>
{
  "lt": {
    "email": "El. paštas",
    "message": "Žinutė",
    "name": "Vardas",
    "lastName": "Pavardė",
    "phone": "Telefono nr.",
    "apva": {
      "question": "Ar gavote APVA paramą?",
      "options": [
        "Taip",
         "Ne"
      ]
    },
    "address": "Adresas",
    "roofType": "Stogo tipas",
    "roofDirection": "Stogo kryptis",
    "monthFee": "Kiek per mėnesį sumokate už elektrą?",
    "send": "Išsiųsti",
    "success": "Dėkojame! Netrukus su jumis susisieksime.",
    "date": "Pasirinkite jums patogų laiką ir datą skambučiui",
    "apva": {
      "question": "Ar gavote APVA paramą?",
      "options": [
        "Taip",
         "Ne"
      ]
    }
  }
}
</i18n>
