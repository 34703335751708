<template functional>
  <a
    :class="['SocialIcon', data.class, data.staticClass]"
    :href="props.href"
    rel="noopener"
    target="_blank"
    :title="props.title">
    <Icon :name="props.icon" />
  </a>
</template>

<script>
export default {
  props: {
    href: String,
    icon: String,
    title: String,
  },
}
</script>

<style lang="scss">
$_size: 2.5rem;

.SocialIcon {
  background: $black-ter;
  border-radius: $radius-round;
  color: $white;
  display: inline-block;
  height: $_size;
  line-height: $_size;
  margin-right: 1rem;
  text-align: center;
  vertical-align: bottom;
  width: $_size;

  &:hover,
  &:focus {
    background: $main;
  }
}
</style>
