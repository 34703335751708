const {
  BASE_URL,
  NODE_ENV,
  VUE_APP_API_BASE,
  VUE_APP_API_ROOT,
  VUE_APP_FB_ID,
  VUE_APP_GA_ID,
  VUE_APP_GTM_ID,
  VUE_APP_I18N_LOCALE,
  VUE_APP_IS_DEPLOYED,
  VUE_APP_IS_MOCK,
  VUE_APP_WEB_URL,
  VUE_APP_GOOGLE_EXPERIMENT_ID,
  VUE_APP_BUGSNAG_KEY,
  VUE_APP_INBANK_ID,
} = process.env

const clientOrigin = window.location.origin

export const basePath = BASE_URL || '/'

const clientUrl = `${clientOrigin}${basePath}`

export const webUrl = VUE_APP_WEB_URL ?? clientUrl

export const webOrigin = webUrl.replace(basePath, '/')

export const isDeployedProduction = VUE_APP_IS_DEPLOYED === 'true'

export const isProduction = isDeployedProduction || NODE_ENV === 'production'

export const isMock = !isDeployedProduction && (VUE_APP_IS_MOCK ?? true).toString() === 'true'

export const defaultLocale = VUE_APP_I18N_LOCALE || 'lt'

export const apiBase = VUE_APP_API_BASE ?? '/api'

export const apiOrigin = VUE_APP_API_ROOT || clientOrigin

export const apiUrl = isMock
  ? `${apiOrigin}/mock`
  : `${apiOrigin}${apiBase}`

export const bugsnagApiKey = VUE_APP_BUGSNAG_KEY

export const googleAnalyticsId = VUE_APP_GA_ID

export const googleTagManagerId = VUE_APP_GTM_ID

export const facebookPixelId = VUE_APP_FB_ID

export const inbankId = VUE_APP_INBANK_ID

// export const formExperimentId = 'JM2BUHK1RDKwLtjS_Y1Kxw'
export const formExperimentId = VUE_APP_GOOGLE_EXPERIMENT_ID

export const recaptchaKey = isDeployedProduction
  ? '6LfqvOMUAAAAAJsIv5JrG2ElP2qfQSaBn2Y3Zvzv'
  : null
