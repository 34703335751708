import { _ } from '@feathersjs/commons'
import modules from './modules'
import { queryObject } from '../views/utils'
import { stateList } from './utils'

// build automatic getters for each module
Object.values(modules).forEach((storeModule) => {
  if (!storeModule.auto.has('getters')) return

  _.merge(storeModule, {
    store: {
      getters: {
        [storeModule.storeKey]: stateList,
        [`${storeModule.storeKey}Query`]: queryObject,
      },
      state: {
        storeSuffix: storeModule.storeSuffix,
        pagination: {},
      },
    },
  })
})

// form global 'query' getter
const globalQueryGetters = {}
modules.forEach((storeModule) => {
  if (!storeModule.storeKey || !storeModule.store || !storeModule.store.getters) return

  const queryGetter = `${storeModule.storeKey}Query`
  const qg = storeModule.store.getters[queryGetter]
  if (qg) globalQueryGetters[storeModule.storeKey] = qg
})

const getters = {
  query: (globalState, localGetters) => (name, params, doSingleOut = false) => {
    return globalQueryGetters[name](globalState[name], localGetters)(params, doSingleOut)
  },
  // queryIn: () => (queryInObject, query, doSingleOut = false) =>
  //   queryObject(queryInObject)(query, doSingleOut),
}

export default getters
