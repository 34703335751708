<template functional>
  <div :class="['Row', data.class, data.staticClass]">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
$_gutter: $grid-gutter-width;

.Row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$_gutter;
  margin-right: -$_gutter;

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  &.no-gutters {
    margin-left: 0;
    margin-right: 0;

    > .col, > [class*='col-'] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

</style>
