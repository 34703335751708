import { isObject } from '../../views/utils'

export default {
  auto: ['actions'],
  store: {
    state: {
      list: [],
    },
    actions: {
      ERROR({ dispatch }, error) {
        // display error to user
        const message = (isObject(error) && error.message)
          ? error.message
          : error

        dispatch('DANGER', message)
      },
    },
  },
}
