<template>
  <Field
    class="FieldConsent"
    name="policy"
    :rules="{ required: { allowFalse: false } }"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      {{ $t('read') }} <Route target="_blank" :title="$t('policy')" to="PrivacyPolicy">
        {{ $t('policy') }}
      </Route>
    </span>
  </Field>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>

<i18n>
{
  "lt": {
    "policy": "privatumo politiką",
    "read": "Aš perskaičiau"
  }
}
</i18n>
