import { isNumeric, isObject } from '.'
import { _ } from '@feathersjs/commons'
import sift from 'sift'
import sorter from './sorter'
import { stateList } from '../../store/utils'

const customExpressions = {
  $ilike: (queryValue, fieldValue) => fieldValue && fieldValue.toLowerCase().includes(queryValue),
  $like: (queryValue, fieldValue) => fieldValue && fieldValue.includes(queryValue),
}

function wrapInPercent(cleanQuery) {
  const fieldsToWrap = ['$like', '$ilike']
  const orQueries = {}

  if (cleanQuery.$or) {
    orQueries.$or = cleanQuery.$or.map(wrapInPercent)
  }

  return {
    ...Object
      .keys(cleanQuery)
      .reduce((wrappedQuery, key) => {
        const condition = cleanQuery[key]

        // wrap $like and $ilike in %
        if (isObject(condition)) {
          const conditionKeys = Object.keys(condition)
          const likeKey = fieldsToWrap.find(key => conditionKeys.includes(key))
          wrappedQuery[key] = likeKey ? { [likeKey]: `%${condition[likeKey]}%` } : condition[key]
        } else {
          wrappedQuery[key] = condition
        }

        return wrappedQuery
      }, {}),
    ...orQueries,
  }
}

function buildServerQuery(query) {
  const { $fetch, $server, ...cleanQuery } = query

  Object.assign(cleanQuery, $server)

  return wrapInPercent(cleanQuery)
}

const Query = {
  _find(state, params) {
    const store = stateList(state)

    if (isNumeric(params)) return store[parseInt(params, 10)]

    const { $fetch, ...preQuery } = params.query || {}
    const { $skip, $limit, $sort, $select, ...query } = preQuery

    let values = Object.values(store).filter(sift(query, {
      expressions: customExpressions,
    }))

    // async fetch on the server
    if ($fetch) {
      const serverQuery = buildServerQuery(preQuery)
      $fetch.$store.dispatch(`FETCH_${state.storeSuffix}`, serverQuery)
    }

    if ($sort) {
      values.sort(typeof $sort === 'function' ? $sort : sorter($sort))
    }

    if ($skip) {
      values = values.slice($skip)
    }

    if (typeof $limit !== 'undefined') {
      values = values.slice(0, $limit)
    }

    if ($select) {
      values = values.map(value => _.pick(value, ...$select))
    }

    return values
  },

  find(state, params = {}) {
    if (isObject(params) && !params.query) {
      params = {
        query: params,
      }
    }

    // Call the internal find with query parameter that include pagination
    return this._find(state, params)
  },
}

export default state => (params, doSingleOut) => {
  const result = Query.find(state, params)

  if (doSingleOut) {
    if (result.length) {
      return result[0]
    } else if (isObject(result)) {
      return result
    }

    return null
  }

  return result
}
